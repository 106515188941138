<template>
 <div class="login-box">
  <div class="login-content">
  <div class="logo">
    <img src="@/assets/img/mu-logo.png"/>
  </div>
  <div class="input-box" v-if="step=='step1'">
    <el-input
      @focus="tips1=''"
      placeholder="请输入手机号/邮箱"
      prefix-icon="el-icon-user"
      v-model="userName">
    </el-input>
    <div v-show="tips1" class="error-tips"><i class="el-icon-error"></i> {{tips1}}</div>
    <button class="wid-btn" @click="toStep2">获取验证码</button>
  </div>
  <div class="input-box" v-if="step=='step2'">
    <div style="position:relative">
      <el-input
        @focus="tips2=''"
        placeholder="请输入验证码"
        prefix-icon="el-icon-key"
        v-model="code">
        <div slot="suffix" style="line-height:40px" @click="getCode" class="theme-color">{{codeText}}</div>
      </el-input>
      <!-- <div class="yzm " @click="getCode">{{codeText}}</div> -->
    </div>
    <div v-if="tips2" class="error-tips"><i class="el-icon-error"></i> {{tips2}}</div>
    <button class="wid-btn" @click="toStep3">确定</button>
  </div>
  <div class="input-box" v-if="step=='step3'">
    <el-input
      @focus="tips3=''"
      placeholder="请输入新密码"
      type="password"
      style="margin-bottom:1rem"
      prefix-icon="el-icon-lock"
      v-model="pwd1">
    </el-input>
    <el-input
      @focus="tips3=''"
      placeholder="请再次输入密码"
      type="password"
      prefix-icon="el-icon-lock"
      v-model="pwd2">
    </el-input>
    <div v-if="tips3" class="error-tips"><i class="el-icon-error"></i> {{tips3}}</div>
    <button class="wid-btn" @click="toLogin">确认重置</button>
  </div>
  </div>
 </div>
</template>
<script>
export default {
  data(){
    return{
      codeText:"重新获取",
      date:60,
      pwd1:'',
      pwd2:'',
      tips1:'',
      tips2:'',
      tips3:'',
      step:"step1",
      userName:'',
      code:'',
      timer: null,
      disabledshow:false,
    }
  },
  methods:{
    // 获取验证码
    getCode(){
      // 如果正在倒计时，禁止再次点击
      if(this.disabledshow){
        return false
      }
      let that =this
      
        let params = {
          account:this.userName,
          action:'forget'
        }
        this.$store
          .dispatch('auth/getcodes',params)
          .then(res => {
            if (res.code === 0) {
              that.disabledshow= true
              if (this.date === 60) {
                this.step = 'step2'
                that.timer = setInterval(() => {
                  this.date--
                  this.codeText = this.date + 's后重新获取'
                  if (this.date === 0) {
                    this.date = 60
                    that.disabledshow= false
                    this.codeText = '重新获取'
                    clearInterval(that.timer)
                    that.timer = null
                  }
                }, 1000)
              }
            } else {
              this.date = 60
              this.codeText = '重新获取'
              that.disabledshow= false
              clearInterval(that.timer)
              that.timer = null
            }
          })
          .catch(err => {
            console.log(err)
          })

    },
    toStep2(){
      // 获取验证码
      if (this.userName === '') {
        this.tips1 = "请输入手机号/邮箱"
        return
      }
      this.getCode()
    },

    toStep3(){
      if (this.code === '') {
        this.tips2 = "验证码不能为空"
        return false
      }
      // 校验验证码
      let params = {
        type:'verify',
        account:this.userName,
        code:this.code
      }
      
      this.$store
        .dispatch('auth/checkscodes',params)
        .then(res => {
          if(res.msg=='验证码正确'){
            this.step = 'step3'
          }
        }) 
    },
    toLogin(){
      if (this.pwd1 === ''||this.pwd2 === '') {
        this.tips3 = "密码不能为空"
        return false
      }else if(this.checkPwd(this.pwd1)==false){
          this.tips3 = "密码格式为长度6～16的数字字母"
          return false
      }else if(this.pwd1!=this.pwd2 ){
        this.tips3 = "两次密码不一致"
        return false
      } else{
        let params = {
          type:'password',
          account:this.userName,
          role:1,
          password:this.pwd1,
          verify:this.code
        }
        this.$store
          .dispatch('auth/updateUser',params)
          .then(() => {
            this.$router.push({name:"login"})
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.login-box{
  padding:.8rem;
  .login-content{
    background: #fff;
    border-radius:8px;
    height: calc(100vh - 1.6rem);
  }
  
  .logo{
    text-align: center;
    padding-top:7rem;
    margin-bottom:2rem;
    img{
      width: 45%;
      margin-bottom:1rem;
    }
    .logo-name{
      letter-spacing: 2px;
      margin-bottom: 1rem;
    }
  }
  .sub-name{
    text-align: center;
    color: #989898;
    font-size: .9rem;
  }
  .btn-box{
    text-align: center;
    button{
      width: 80%;
    }
    margin: 1rem;
  }
}
.input-box{
  width: 90%;
  margin: 0px auto;
  // margin-left:10%;
  position: relative;
  // >:first-child{
  //    margin-bottom: 1rem;
  // }
  .el-input{
    width: 100%;
  }
  button{
    width: 96%;
  }
  .error-tips{
    height: 1rem;
    font-size: .9rem;
    color: #f56c6c;
    margin-top: .3rem;
  }
}
.foget{
  text-align: right;
  font-size: .8rem;
  margin:2% 0rem 1rem;
  cursor: pointer;
}
.yzm{
  position: absolute;
  right: 10px;
  top:12px;
  font-size: 14px;
  cursor: pointer;
}
.wid-btn{
  margin-top:1rem
}
</style>
